.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
  font-size: 32px;
  color: red;
}
========================================== */

.icon-arrow {
    width: #{ms(0)}rem;
}

.icon-next {
    width: #{ms(3)}rem;
}

