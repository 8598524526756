.nc-card {
    box-shadow: 1px 1px 5px 0 rgba(0,0,0, 0.3);
    background-color: $white;
    height: 100%;
    padding-bottom: 0;
    border: 6px solid $dark-teal;
    
    a {
        text-decoration: none;
    }
    
    &:hover {
        box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
        border-image: linear-gradient(mix($white, $dark-teal, 25%), #4d9f0c) 30;
        
        img {
            transform: rotate(5deg) scale(1.1);
        }
        
    }
    
    &-preview-image {
        border-bottom: 1px solid $dark-teal; 
        overflow: hidden;
        
        img {
            transition: transform 0.3s ease-in-out;
        }
    }

    &-details {
        
        dd {
            padding-left: #{ms(1)}em;
        }
    }
}