////////////////////////////////
// !THEME / COMPONENTS / BUTTONS
////////////////////////////////
.nc-theme {
    .dcf-btn {
        appearance: none; // remove after added to DCF core
        // border-radius: 0;
        // border-style: solid;
        // border-width: 2px;
        @include font-sans;
        @include txt-xs;
        font-weight: bold;
        @include ls-2; //   @include lh1;
        // padding-bottom: #{ms(-1)}em;
        @include pl-6;
        @include pr-6;
        @include pt-4;
        @include pb-4;
        // text-transform: uppercase;
        position: relative;
        
        transform: skewX(-15deg);
        margin-right: #{ms(0)}rem;
        transition: all 1s ease-in-out;

        &:before {
            border-width: 2px;
            border-style: solid;
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 3px;
        }

        .icon-arrow-wrapper {
            position: relative;
            padding-left: #{ms(-12)}rem;
            padding-right: #{ms(0)}rem;
            transition: 0.5s;
            display: inline;
        }
        
        &:hover {
            .icon-arrow-wrapper {
                // margin-left: #{ms(-6)}rem;
                margin-right: #{ms(-6)}rem;
            }
        }

        .icon-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: 0.5s; // width: 66px;
            right: 0;
        }

    }

    // !Button Hierarchy
    // !Primary Button
    .dcf-btn-primary {
        &,
        &:link {
            background-color: $color-button;
            // border-color: $color-button;
            color: $white;
            transition: border $hover-off, background-color $hover-off;
            box-shadow: 6px 6px 0 $black;
    
            &:before {
                border-color: $teal;
            }
        }
    
        &:focus {
            // box-shadow: inset 0 0 0 3px currentColor;
            transform: skewX(-15deg) translateY(15%);
            .arrow-one {
                animation: primary-arrow-anim 1s infinite 0.3s;
            }

            .arrow-two {
                transform: translateX(5px);
                animation: primary-arrow-anim 1s infinite 0.5s linear;
            }

            .arrow-three {
                transform: translateX(10px);
                animation: primary-arrow-anim 1s infinite 0.9s;
            }
        }
    
        &:visited {
            background-color: mix($white, $color-button-visited, 30%);
            border-color: transparent;
            color: $white;
            box-shadow: 6px 6px 0 mix($white, $color-button-visited, 50%);

            &:before {
                border-color: mix($white, $color-button-visited, 50%);
            }
        }
    
        &:hover {
            background-color: $color-button-hover;
            border-color: $color-button-hover;
            color: $white;
            transition: border $hover-on, background-color $hover-on;
            box-shadow: 10px 10px 0 #4d9f0c;

            .arrow-one {
                animation: primary-arrow-anim 1s infinite 0.3s;
            }

            .arrow-two {
                transform: translateX(5px);
                animation: primary-arrow-anim 1s infinite 0.5s linear;
            }

            .arrow-three {
                transform: translateX(10px);
                animation: primary-arrow-anim 1s infinite 0.9s;
            }
        }
    
        &:active,
        &.dcf-active {
            background-color: $color-button-active;
            border-color: $color-button-active;
            color: $white;
        }
    
        
    }
    // !Secondary Button
    .dcf-btn-secondary {
        &,
        &:link {
            background-color: $teal; // border-color: currentColor;
            color: $color-button;
            transition: background-color $hover-off, border-color $hover-off, color $hover-off;
            box-shadow: 6px 6px 0 $black;
        }

        .arrow-one, .arrow-two, .arrow-three {
            fill: #fff; // provide default fill color
        }

        &:focus {
            transform: skewX(-15deg) translateY(15%);

            .arrow-one {
                animation: secondary-arrow-anim 1s infinite 0.3s;
            }

            .arrow-two {
                transform: translateX(5px);
                animation: secondary-arrow-anim 1s infinite 0.5s linear;
            }

            .arrow-three {
                transform: translateX(10px);
                animation: secondary-arrow-anim 1s infinite 0.9s;
            }
        }

        &:visited {

            background-color: mix($white, $color-button-visited, 30%);
            border-color: transparent;
            color: $white;
            box-shadow: 6px 6px 0 mix($white, $color-button-visited, 50%);
            &:before {
                border-color: mix($white, $color-button-visited, 50%);
            }
        }

        &:hover {
            background-color: fade-out($color-button-hover, .9);
            border-color: currentColor;
            color: $color-button-hover;
            transition: background-color $hover-on, border-color $hover-on, color $hover-on;
            box-shadow: 10px 10px 0 #4d9f0c;
            .arrow-one {
                animation: secondary-arrow-anim 1s infinite 0.3s;
            }

            .arrow-two {
                transform: translateX(5px);
                animation: secondary-arrow-anim 1s infinite 0.5s linear;
            }

            .arrow-three {
                transform: translateX(10px);
                animation: secondary-arrow-anim 1s infinite 0.9s;
            }
        }

        &:active,
        &.dcf-active {
            border-color: currentColor;
            background-color: fade-out($color-button-hover, .9);
        }
    }
    

    // !Tertiary Button
    .dcf-btn-tertiary,
    .dcf-btn-tertiary:link {
        background-color: transparent;
        border: 1px solid transparent;
        color: $color-button;
        text-decoration: underline;
        text-decoration-skip: ink;
        transition: color $hover-off;
    }

    .dcf-btn-tertiary:focus {
        box-shadow: inset 0 0 0 3px $white, inset 0 0 0 4px currentColor;
    }

    .dcf-btn-tertiary:visited {
        color: $color-button-visited;
    }

    .dcf-btn-tertiary:hover {
        color: $color-button-hover;
        transition: color $hover-on;
    }

    .dcf-btn-tertiary:active,
    .dcf-btn-tertiary.dcf-active {
        color: $color-button-active;
    }

    // !Inverse Primary Button
    .dcf-btn-primary-inverse {
        background-color: $faint-gray;
        border-color: $faint-gray; // TODO: build more color flexibility into inverse styles
        //   color: currentColor;
        transition: background-color $hover-off, border $hover-off;
    }

    .dcf-btn-primary-inverse:hover {
        background-color: $white;
        border-color: $white;
        transition: background-color $hover-on, border $hover-on;
    }

    // !Inverse Secondary Button
    .dcf-btn-secondary-inverse {
        background-color: rgba(0, 0, 0, .1);
        border-color: currentColor;
        color: $white;
        transition: background-color $hover-off, border $hover-off;
    }

    .dcf-btn-secondary-inverse:hover {
        background-color: rgba(0, 0, 0, .2);
        transition: background-color $hover-on, border $hover-on;
    }
}

/* SVG animations */
@keyframes primary-arrow-anim {
    0% {
        fill: $white;
    }
    50% {
        fill:#4d9f0c;
    }
    100% {
        fill: $white;
    }
}

@keyframes secondary-arrow-anim {
    0% {
        fill: $dark-teal;
    }
    50% {
        fill: #4d9f0c;
    }
    100% {
        fill: $dark-teal;
    }
}