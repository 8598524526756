@charset "UTF-8";
*,
*::before,
*::after {
  box-sizing: border-box; }

:disabled {
  cursor: not-allowed !important;
  opacity: .7; }

:root {
  font-size: 1em;
  line-height: 1.5; }

@media screen and (min-width: 31.56929em) {
  :root {
    font-size: calc(1em + (1.33333 - 1) * ( (100vw - 31.56929em) / ( 177.37698 - 31.56929))); } }

@media screen and (min-width: 177.37698em) {
  :root {
    font-size: 1.33333em; } }

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0.9); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(50%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

abbr {
  letter-spacing: .05em; }

abbr[title] {
  position: relative;
  text-decoration: none; }
  abbr[title]::after {
    border-bottom: 1px dotted currentColor;
    content: '';
    height: 100%;
    left: 0;
    opacity: .5;
    position: absolute;
    top: 0;
    width: 100%; }

address {
  font-style: normal;
  margin-top: 0; }

a {
  text-decoration-skip: ink; }

a:link {
  color: #144b60;
  transition: color 400ms ease-out; }

a:visited {
  color: #0b2a36; }

a:hover {
  color: #0b2a36;
  transition: color 200ms ease-out; }

a:active {
  color: #0b2a36; }

blockquote {
  max-width: 31.56929rem; }

body {
  color: #3B3C3C;
  background-color: #fbfbfb;
  margin: 0;
  overflow-x: hidden; }

button,
input[type="button"],
input[type="submit"] {
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  line-height: 1.5;
  padding-bottom: 0.5625em;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5625em;
  text-align: center; }

code,
kbd,
pre {
  border-radius: 2px; }

code,
kbd {
  font-size: 0.84375em;
  padding: 0.13348rem 0.2373rem; }

code,
pre {
  hyphens: none;
  word-break: normal; }

code {
  background-color: rgba(0, 0, 0, 0.05);
  color: #545656; }

pre {
  tab-size: 2; }

pre code {
  background-color: transparent;
  display: block;
  overflow-x: scroll;
  padding: 0;
  white-space: pre; }

kbd {
  background-color: #000;
  color: #0f0; }

kbd kbd {
  font-size: 100%;
  padding: 0; }

dialog {
  background-color: transparent;
  border: none !important;
  padding: 0;
  transform-origin: center;
  width: 80vw; }
  dialog::backdrop {
    background-color: rgba(0, 0, 0, 0.96); }
    @supports (backdrop-filter: blur(6px)) {
      dialog::backdrop {
        background-color: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(6px) grayscale(10%); } }
  dialog[open] {
    animation: appear 300ms forwards ease-out; }

figure {
  margin: 0; }

form,
input,
textarea {
  font-size: 1em;
  line-height: 1.5; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  font-size: 1.33333em; }

label {
  display: inline-block; }

select {
  display: block;
  margin-bottom: 1em; }

textarea {
  display: block;
  resize: vertical;
  width: 100%; }

h1 {
  font-size: 2.66667em; }

h2 {
  font-size: 2.37037em; }

h3 {
  font-size: 2.0em; }

h4 {
  font-size: 1.77778em; }

h5 {
  font-size: 1.5em; }

h6 {
  font-size: 1.33333em; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #3B3C3C;
  line-height: 1.125;
  margin-bottom: 1rem;
  margin-top: 0; }
  p + h1,
  ol + h1,
  ul + h1,
  dl + h1, p +
  h2,
  ol +
  h2,
  ul +
  h2,
  dl +
  h2, p +
  h3,
  ol +
  h3,
  ul +
  h3,
  dl +
  h3, p +
  h4,
  ol +
  h4,
  ul +
  h4,
  dl +
  h4, p +
  h5,
  ol +
  h5,
  ul +
  h5,
  dl +
  h5, p +
  h6,
  ol +
  h6,
  ul +
  h6,
  dl +
  h6 {
    margin-top: 1.33333em; }

img {
  height: auto;
  max-width: 100%; }

ol,
ul {
  padding-left: 1.77778em; }

dl,
ol,
ul {
  margin-bottom: 1em;
  margin-top: 0; }
  dl dl,
  dl ol,
  dl ul,
  ol dl,
  ol ol,
  ol ul,
  ul dl,
  ul ol,
  ul ul {
    margin-top: 0.75em; }

li,
dt,
dd {
  margin-top: 0; }

li:not(:last-child) {
  margin-bottom: 0.5625em; }

dt,
dd:not(:last-child) {
  margin-bottom: 0.42188em; }

dt {
  font-weight: bold; }

dd {
  margin-left: 0; }

dd + dt {
  margin-top: 1em; }

main {
  display: block; }

mark {
  background-color: #ff0;
  padding-bottom: 0.13348em;
  padding-left: 0.42188em;
  padding-right: 0.42188em;
  padding-top: 0.13348em; }

p {
  margin-bottom: 1rem;
  margin-top: 0; }

body:lang(en) {
  quotes: '“' '”' '‘' '’' '“' '”' '‘' '’'; }

q::before {
  content: open-quote; }

q::after {
  content: close-quote; }

small {
  font-size: 1rem; }

sup,
sub {
  font-size: -0.63281em;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5625em; }

sub {
  top: 0.2373em; }

table {
  background-color: transparent;
  border-collapse: collapse; }

caption {
  caption-side: bottom;
  color: #787979;
  font-size: 0.75em;
  padding-top: 0.5625rem;
  text-align: left; }

th {
  text-align: left; }

article,
section {
  font-size: 1rem; }

body {
  position: relative; }

h1 {
  color: #144b60; }
  @supports (-webkit-text-fill-color: transparent) {
    h1 {
      background: linear-gradient(90deg, #144b60 0%, #4d9f0c 90%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; } }

h2,
h3,
h4,
h5,
h6 {
  padding-top: 1.77778em; }

.dcf-grid,
.dcf-grid-halves,
.dcf-grid-halves\@sm,
.dcf-grid-halves\@md,
.dcf-grid-halves\@lg,
.dcf-grid-halves\@xl,
.dcf-grid-thirds,
.dcf-grid-thirds\@sm,
.dcf-grid-thirds\@md,
.dcf-grid-thirds\@lg,
.dcf-grid-thirds\@xl,
.dcf-grid-fourths,
.dcf-grid-fourths\@sm,
.dcf-grid-fourths\@md,
.dcf-grid-fourths\@lg,
.dcf-grid-fourths\@xl,
.dcf-grid-fifths\@md,
.dcf-grid-fifths\@lg,
.dcf-grid-fifths\@xl,
.dcf-grid-sixths\@md,
.dcf-grid-sixths\@lg,
.dcf-grid-sixths\@xl {
  display: flex;
  flex-wrap: wrap; }

.dcf-grid-halves > * {
  flex-basis: 50%; }

.dcf-grid-thirds > * {
  flex-basis: 33%; }

.dcf-grid-fourths > * {
  flex-basis: 25%; }

[class*='dcf-col'] {
  flex-basis: 100%; }

.dcf-col-50\%-start {
  flex-basis: 50%; }

.dcf-col-50\%-end {
  flex-basis: 50%; }

.dcf-col-33\%-start {
  flex-basis: 33%; }

.dcf-col-33\%-end {
  flex-basis: 33%; }

.dcf-col-67\%-start {
  flex-basis: 67%; }

.dcf-col-67\%-end {
  flex-basis: 67%; }

.dcf-col-25\%-start {
  flex-basis: 25%; }

.dcf-col-25\%-end {
  flex-basis: 25%; }

.dcf-col-75\%-start {
  flex-basis: 75%; }

.dcf-col-75\%-end {
  flex-basis: 75%; }

@media only screen and (min-width: 41.95625em) {
  .dcf-grid-halves\@sm > * {
    flex-basis: 50%; }
  .dcf-grid-thirds\@sm > * {
    flex-basis: 33%; }
  .dcf-grid-fourths\@sm > * {
    flex-basis: 25%; }
  .dcf-col-50\%-start\@sm {
    flex-basis: 50%; }
  .dcf-col-50\%-end\@sm {
    flex-basis: 50%; }
  .dcf-col-33\%-start\@sm {
    flex-basis: 33%; }
  .dcf-col-33\%-end\@sm {
    flex-basis: 33%; }
  .dcf-col-67\%-start\@sm {
    flex-basis: 67%; }
  .dcf-col-67\%-end\@sm {
    flex-basis: 67%; }
  .dcf-col-25\%-start\@sm {
    flex-basis: 25%; }
  .dcf-col-25\%-end\@sm {
    flex-basis: 25%; }
  .dcf-col-75\%-start\@sm {
    flex-basis: 75%; }
  .dcf-col-75\%-end\@sm {
    flex-basis: 75%; } }

@media only screen and (min-width: 55.925em) {
  .dcf-grid-halves\@md > * {
    flex-basis: 50%; }
  .dcf-grid-thirds\@md > * {
    flex-basis: 33%; }
  .dcf-grid-fourths\@md > * {
    flex-basis: 25%; }
  .dcf-grid-fifths\@md > * {
    flex-basis: 20%; }
  .dcf-grid-sixths\@md > * {
    flex-basis: 16.66%; }
  .dcf-col-50\%-start\@md {
    flex-basis: 50%; }
  .dcf-col-50\%-end\@md {
    flex-basis: 50%; }
  .dcf-col-33\%-start\@md {
    flex-basis: 33%; }
  .dcf-col-33\%-end\@md {
    flex-basis: 33%; }
  .dcf-col-67\%-start\@md {
    flex-basis: 67%; }
  .dcf-col-67\%-end\@md {
    flex-basis: 67%; }
  .dcf-col-25\%-start\@md {
    flex-basis: 25%; }
  .dcf-col-25\%-end\@md {
    flex-basis: 25%; }
  .dcf-col-75\%-start\@md {
    flex-basis: 75%; }
  .dcf-col-75\%-end\@md {
    flex-basis: 75%; } }

@media only screen and (min-width: 74.55em) {
  .dcf-grid-halves\@lg > * {
    flex-basis: 50%; }
  .dcf-grid-thirds\@lg > * {
    flex-basis: 33%; }
  .dcf-grid-fourths\@lg > * {
    flex-basis: 25%; }
  .dcf-grid-fifths\@lg > * {
    flex-basis: 20%; }
  .dcf-grid-sixths\@lg > * {
    flex-basis: 16.66%; }
  .dcf-col-50\%-start\@lg {
    flex-basis: 50%; }
  .dcf-col-50\%-end\@lg {
    flex-basis: 50%; }
  .dcf-col-33\%-start\@lg {
    flex-basis: 33%; }
  .dcf-col-33\%-end\@lg {
    flex-basis: 33%; }
  .dcf-col-67\%-start\@lg {
    flex-basis: 67%; }
  .dcf-col-67\%-end\@lg {
    flex-basis: 67%; }
  .dcf-col-25\%-start\@lg {
    flex-basis: 25%; }
  .dcf-col-25\%-end\@lg {
    flex-basis: 25%; }
  .dcf-col-75\%-start\@lg {
    flex-basis: 75%; }
  .dcf-col-75\%-end\@lg {
    flex-basis: 75%; } }

@media only screen and (min-width: 99.375em) {
  .dcf-grid-halves\@xl > * {
    flex-basis: 50%; }
  .dcf-grid-thirds\@xl > * {
    flex-basis: 33%; }
  .dcf-grid-fourths\@xl > * {
    flex-basis: 25%; }
  .dcf-grid-fifths\@xl > * {
    flex-basis: 20%; }
  .dcf-grid-sixths\@xl > * {
    flex-basis: 16.66%; }
  .dcf-col-50\%-start\@xl {
    flex-basis: 50%; }
  .dcf-col-50\%-end\@xl {
    flex-basis: 50%; }
  .dcf-col-33\%-start\@xl {
    flex-basis: 33%; }
  .dcf-col-33\%-end\@xl {
    flex-basis: 33%; }
  .dcf-col-67\%-start\@xl {
    flex-basis: 67%; }
  .dcf-col-67\%-end\@xl {
    flex-basis: 67%; }
  .dcf-col-25\%-start\@xl {
    flex-basis: 25%; }
  .dcf-col-25\%-end\@xl {
    flex-basis: 25%; }
  .dcf-col-75\%-start\@xl {
    flex-basis: 75%; }
  .dcf-col-75\%-end\@xl {
    flex-basis: 75%; } }

@supports (display: grid) {
  .dcf-grid,
  .dcf-grid-halves,
  .dcf-grid-halves\@sm,
  .dcf-grid-halves\@md,
  .dcf-grid-halves\@lg,
  .dcf-grid-halves\@xl,
  .dcf-grid-thirds,
  .dcf-grid-thirds\@sm,
  .dcf-grid-thirds\@md,
  .dcf-grid-thirds\@lg,
  .dcf-grid-thirds\@xl,
  .dcf-grid-fourths,
  .dcf-grid-fourths\@sm,
  .dcf-grid-fourths\@md,
  .dcf-grid-fourths\@lg,
  .dcf-grid-fourths\@xl,
  .dcf-grid-fifths\@md,
  .dcf-grid-fifths\@lg,
  .dcf-grid-fifths\@xl,
  .dcf-grid-sixths\@md,
  .dcf-grid-sixths\@lg,
  .dcf-grid-sixths\@xl {
    display: grid; }
  .dcf-grid {
    grid-template-columns: repeat(12, 1fr); }
  .dcf-grid-halves {
    grid-template-columns: repeat(2, 1fr); }
  .dcf-grid-thirds {
    grid-template-columns: repeat(3, 1fr); }
  .dcf-grid-fourths {
    grid-template-columns: repeat(4, 1fr); }
  [class*='dcf-col'] {
    grid-column: 1 / span 12; }
  .dcf-col-50\%-start {
    grid-column: 1 / span 6; }
  .dcf-col-50\%-end {
    grid-column: span 6 / -1; }
  .dcf-col-33\%-start {
    grid-column: 1 / span 4; }
  .dcf-col-33\%-end {
    grid-column: span 4 / -1; }
  .dcf-col-67\%-start {
    grid-column: 1 / span 8; }
  .dcf-col-67\%-end {
    grid-column: span 8 / -1; }
  .dcf-col-25\%-start {
    grid-column: 1 / span 3; }
  .dcf-col-25\%-end {
    grid-column: span 3 / -1; }
  .dcf-col-75\%-start {
    grid-column: 1 / span 9; }
  .dcf-col-75\%-end {
    grid-column: span 9 / -1; }
  @media only screen and (min-width: 41.95625em) {
    .dcf-grid-halves\@sm {
      grid-template-columns: repeat(2, 1fr); }
    .dcf-grid-thirds\@sm {
      grid-template-columns: repeat(3, 1fr); }
    .dcf-grid-fourths\@sm {
      grid-template-columns: repeat(4, 1fr); }
    .dcf-col-50\%-start\@sm {
      grid-column: 1 / span 6; }
    .dcf-col-50\%-end\@sm {
      grid-column: span 6 / -1; }
    .dcf-col-33\%-start\@sm {
      grid-column: 1 / span 4; }
    .dcf-col-33\%-end\@sm {
      grid-column: span 4 / -1; }
    .dcf-col-67\%-start\@sm {
      grid-column: 1 / span 8; }
    .dcf-col-67\%-end\@sm {
      grid-column: span 8 / -1; }
    .dcf-col-25\%-start\@sm {
      grid-column: 1 / span 3; }
    .dcf-col-25\%-end\@sm {
      grid-column: span 3 / -1; }
    .dcf-col-75\%-start\@sm {
      grid-column: 1 / span 9; }
    .dcf-col-75\%-end\@sm {
      grid-column: span 9 / -1; } }
  @media only screen and (min-width: 55.925em) {
    .dcf-grid-halves\@md {
      grid-template-columns: repeat(2, 1fr); }
    .dcf-grid-thirds\@md {
      grid-template-columns: repeat(3, 1fr); }
    .dcf-grid-fourths\@md {
      grid-template-columns: repeat(4, 1fr); }
    .dcf-grid-fifths\@md {
      grid-template-columns: repeat(5, 1fr); }
    .dcf-grid-sixths\@md {
      grid-template-columns: repeat(6, 1fr); }
    .dcf-col-50\%-start\@md {
      grid-column: 1 / span 6; }
    .dcf-col-50\%-end\@md {
      grid-column: span 6 / -1; }
    .dcf-col-33\%-start\@md {
      grid-column: 1 / span 4; }
    .dcf-col-33\%-end\@md {
      grid-column: span 4 / -1; }
    .dcf-col-67\%-start\@md {
      grid-column: 1 / span 8; }
    .dcf-col-67\%-end\@md {
      grid-column: span 8 / -1; }
    .dcf-col-25\%-start\@md {
      grid-column: 1 / span 3; }
    .dcf-col-25\%-end\@md {
      grid-column: span 3 / -1; }
    .dcf-col-75\%-start\@md {
      grid-column: 1 / span 9; }
    .dcf-col-75\%-end\@md {
      grid-column: span 9 / -1; } }
  @media only screen and (min-width: 74.55em) {
    .dcf-grid-halves\@lg {
      grid-template-columns: repeat(2, 1fr); }
    .dcf-grid-thirds\@lg {
      grid-template-columns: repeat(3, 1fr); }
    .dcf-grid-fourths\@lg {
      grid-template-columns: repeat(4, 1fr); }
    .dcf-grid-fifths\@lg {
      grid-template-columns: repeat(5, 1fr); }
    .dcf-grid-sixths\@lg {
      grid-template-columns: repeat(6, 1fr); }
    .dcf-col-50\%-start\@lg {
      grid-column: 1 / span 6; }
    .dcf-col-50\%-end\@lg {
      grid-column: span 6 / -1; }
    .dcf-col-33\%-start\@lg {
      grid-column: 1 / span 4; }
    .dcf-col-33\%-end\@lg {
      grid-column: span 4 / -1; }
    .dcf-col-67\%-start\@lg {
      grid-column: 1 / span 8; }
    .dcf-col-67\%-end\@lg {
      grid-column: span 8 / -1; }
    .dcf-col-25\%-start\@lg {
      grid-column: 1 / span 3; }
    .dcf-col-25\%-end\@lg {
      grid-column: span 3 / -1; }
    .dcf-col-75\%-start\@lg {
      grid-column: 1 / span 9; }
    .dcf-col-75\%-end\@lg {
      grid-column: span 9 / -1; } }
  @media only screen and (min-width: 99.375em) {
    .dcf-grid-halves\@xl {
      grid-template-columns: repeat(2, 1fr); }
    .dcf-grid-thirds\@xl {
      grid-template-columns: repeat(3, 1fr); }
    .dcf-grid-fourths\@xl {
      grid-template-columns: repeat(4, 1fr); }
    .dcf-grid-fifths\@xl {
      grid-template-columns: repeat(5, 1fr); }
    .dcf-grid-sixths\@xl {
      grid-template-columns: repeat(6, 1fr); }
    .dcf-col-50\%-start\@xl {
      grid-column: 1 / span 6; }
    .dcf-col-50\%-end\@xl {
      grid-column: span 6 / -1; }
    .dcf-col-33\%-start\@xl {
      grid-column: 1 / span 4; }
    .dcf-col-33\%-end\@xl {
      grid-column: span 4 / -1; }
    .dcf-col-67\%-start\@xl {
      grid-column: 1 / span 8; }
    .dcf-col-67\%-end\@xl {
      grid-column: span 8 / -1; }
    .dcf-col-25\%-start\@xl {
      grid-column: 1 / span 3; }
    .dcf-col-25\%-end\@xl {
      grid-column: span 3 / -1; }
    .dcf-col-75\%-start\@xl {
      grid-column: 1 / span 9; }
    .dcf-col-75\%-end\@xl {
      grid-column: span 9 / -1; } } }

.dcf-list-bare {
  list-style: none;
  padding-left: 0; }

.dcf-list-inline li {
  display: inline-block; }

.dcf-list-inline li:not(:last-child) {
  margin-right: 0.5625em; }

.dcf-media {
  align-items: flex-start;
  display: flex; }

.dcf-media-body {
  flex: 1; }

.dcf-stretch {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  right: 50%;
  width: 100vw; }

.dcf-wrapper {
  padding-left: 5.61866vw;
  padding-right: 5.61866vw; }

.dcf-alert {
  display: grid;
  grid-template-areas: "header footer" "msg footer"; }

.dcf-alert-header {
  grid-area: header; }

.dcf-alert-msg {
  grid-area: msg; }

.dcf-alert-footer {
  grid-area: footer; }

.dcf-article-body dl {
  padding-left: 1.77778em; }

.dcf-article-body dl,
.dcf-article-body ol,
.dcf-article-body ul {
  margin-bottom: 0; }

.dcf-article p + p,
.dcf-article p + dl,
.dcf-article p + ol,
.dcf-article p + ul,
.dcf-article dl + p,
.dcf-article dl + dl,
.dcf-article dl + ol,
.dcf-article dl + ul,
.dcf-article ol + p,
.dcf-article ol + dl,
.dcf-article ol + ol,
.dcf-article ol + ul,
.dcf-article ul + p,
.dcf-article ul + dl,
.dcf-article ul + ol,
.dcf-article ul + ul,
.dcf-article blockquote + p,
.dcf-article blockquote + dl,
.dcf-article blockquote + ol,
.dcf-article blockquote + ul {
  margin-top: 0.42188em; }

.dcf-article p + p,
.dcf-article dl + p,
.dcf-article ol + p,
.dcf-article ul + p,
.dcf-article blockquote + p {
  text-indent: 1.77778em; }

.dcf-article-body p:not(.dcf-lead) {
  margin-bottom: 0; }

.dcf-author-img img {
  display: block;
  width: 3.16049em; }

@media only screen and (max-width: 41.95625em) {
  .dcf-article-share li {
    display: inline-flex;
    margin-bottom: 0;
    margin-right: 1em; } }

@media only screen and (min-width: 41.95625em) {
  .dcf-article-share div[role="heading"] {
    margin-bottom: 1.33333em; }
  .dcf-article-share li {
    margin-bottom: 1em; } }

@supports (display: grid) {
  @media only screen and (min-width: 41.95625em) {
    .dcf-article {
      display: grid;
      grid-template-columns: 3.16049em 1fr;
      grid-template-areas: "share header" "share body" "footer footer"; }
    .dcf-article-header {
      grid-area: header; }
    .dcf-article-body {
      grid-area: body; }
    .dcf-article-share {
      grid-area: share; }
      .dcf-article-share .dcf-u-sticky {
        top: 1.33333em; }
      .dcf-article-share div[role="heading"] {
        margin-bottom: 1.77778em; }
    .dcf-article-footer {
      grid-area: footer; } } }

.dcf-badge {
  background-color: #fdfbfb;
  color: #fff;
  display: inline-block;
  font-size: 0.5625em;
  line-height: 1.5;
  padding: 0.07508rem 0.42188em;
  text-align: center;
  vertical-align: top;
  white-space: nowrap; }

.dcf-badge-roundrect {
  border-radius: 2px; }

.dcf-badge-pill {
  border-radius: 1em;
  padding-left: 0.5625em;
  padding-right: 0.5625em; }

.dcf-blockquote {
  margin-bottom: 2.37037em;
  margin-left: 0;
  margin-right: 0;
  position: relative; }

.dcf-blockquote::before {
  content: open-quote;
  font-size: 9.98872em;
  line-height: 1;
  position: absolute; }

.dcf-blockquote p {
  font-size: 1.33333em;
  padding-top: 3.55556em; }

.dcf-blockquote p::after {
  content: close-quote; }

.dcf-attribution {
  margin-top: 1em; }

.dcf-attribution::before {
  content: '— '; }

.dcf-breadcrumbs li {
  align-items: center;
  display: flex; }

.dcf-btn-group {
  display: inline-flex; }

.dcf-btn-group .dcf-btn:focus {
  z-index: 1; }

.dcf-btn {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap; }

a.dcf-btn-primary,
a.dcf-btn-secondary {
  text-decoration: none; }

.dcf-card {
  margin-bottom: 0; }

.dcf-card img {
  display: block;
  width: 100%; }

.dcf-card-block {
  padding-bottom: 1.33333em;
  padding-left: 1.77778em;
  padding-top: 1.33333em;
  padding-right: 1.77778em; }

.dcf-card-block p:last-child {
  margin-bottom: 0; }

.dcf-pre {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 1em; }

.dcf-calendar-caption {
  caption-side: top;
  font-size: 1em; }

.dcf-calendar-cell {
  width: 14.28%; }

.dcf-calendar-weekday {
  color: #787979; }

a.dcf-calendar-date {
  outline: 2px solid transparent;
  transition: all 400ms ease-out; }

a.dcf-calendar-date:hover {
  outline: 2px solid #0b2a36;
  transition: all 200ms ease-out; }

a.dcf-calendar-date:active {
  outline: 2px solid #0b2a36; }

a.dcf-calendar-date:focus {
  outline: 2px solid #0b2a36; }

a.dcf-calendar-date-today {
  background-color: #3B3C3C; }

a.dcf-calendar-date-active {
  background-color: #144b60;
  transition: all 400ms ease-out; }

a.dcf-calendar-date-active:hover {
  background-color: #0b2a36;
  transition: all 200ms ease-out; }

a.dcf-calendar-date-active:focus {
  background-color: #0b2a36;
  transition: all 200ms ease-out; }

.dcf-calendar-not-current-month {
  background-color: #f3f3f3; }

@supports (display: grid) {
  .dcf-events {
    display: grid;
    grid-template-areas: "heading" "listing" "more";
    grid-template-columns: auto; }
    @media only screen and (min-width: 74.55em) {
      .dcf-events {
        grid-template-areas: "heading more" "listing listing";
        grid-template-columns: 1fr auto; } }
  .dcf-events-heading {
    grid-area: heading;
    margin-bottom: 0; }
  .dcf-events-more {
    grid-area: more; }
  .dcf-events-listing {
    display: grid;
    grid-area: listing;
    grid-template-columns: repeat(auto-fit, minmax(17.75773em, 1fr)); }
  .dcf-event {
    margin-bottom: 0; } }

.dcf-event-info {
  flex: 1 0 1px; }

.dcf-event-date-time {
  flex: 0 1 4.21399em; }

.dcf-figcaption {
  color: #787979;
  font-size: 0.75em; }

.dcf-label {
  flex-shrink: 0; }

.dcf-input-text {
  appearance: none;
  display: block;
  font-family: inherit; }

.dcf-input-file {
  display: block; }

.dcf-header a {
  text-decoration: none; }

.dcf-header button {
  color: #144b60; }

.dcf-header button:hover {
  color: #0b2a36; }

.dcf-header button:active {
  color: #0b2a36; }

.dcf-h1,
.dcf-h2,
.dcf-h3,
.dcf-h4,
.dcf-h5,
.dcf-h6 {
  margin-bottom: 1rem;
  margin-top: 0;
  line-height: 1.125; }
  p + .dcf-h1,
  ol + .dcf-h1,
  ul + .dcf-h1,
  dl + .dcf-h1, p +
  .dcf-h2,
  ol +
  .dcf-h2,
  ul +
  .dcf-h2,
  dl +
  .dcf-h2, p +
  .dcf-h3,
  ol +
  .dcf-h3,
  ul +
  .dcf-h3,
  dl +
  .dcf-h3, p +
  .dcf-h4,
  ol +
  .dcf-h4,
  ul +
  .dcf-h4,
  dl +
  .dcf-h4, p +
  .dcf-h5,
  ol +
  .dcf-h5,
  ul +
  .dcf-h5,
  dl +
  .dcf-h5, p +
  .dcf-h6,
  ol +
  .dcf-h6,
  ul +
  .dcf-h6,
  dl +
  .dcf-h6 {
    margin-top: 1.33333em; }

.dcf-subhead {
  display: block;
  font-size: 0.75rem;
  margin-bottom: 1em;
  text-transform: uppercase; }

.dcf-hero video,
.dcf-hero img {
  display: block;
  width: 100%; }
  @supports (object-fit: cover) {
    .dcf-hero video,
    .dcf-hero img {
      height: 100%;
      object-fit: cover; } }

@media only screen and (min-width: 55.925em) {
  .dcf-hero {
    height: 75vh;
    min-height: 31.56929em;
    position: relative; }
  .dcf-hero-layer {
    height: 100%;
    position: absolute;
    width: 100%; }
  .dcf-hero-fg {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 2; }
  .dcf-hero-bg {
    overflow: hidden;
    z-index: 1; } }

a .dcf-icon,
button .dcf-icon {
  fill: currentColor; }

.dcf-icon-inline {
  height: 1em;
  width: 1em; }

.dcf-icon-x {
  position: relative;
  top: 0.13348em; }

.dcf-icon-hang {
  left: 0;
  position: absolute; }

@media only screen and (max-width: 55.925em) {
  .dcf-idm-toggle {
    left: 75%; } }

@media only screen and (min-width: 55.925em) {
  @supports (display: grid) {
    .dcf-idm {
      grid-area: idm; } } }

@supports (display: grid) {
  .dcf-dashboard {
    display: grid;
    grid-template-areas: "one two";
    grid-template-columns: 1fr 1fr;
    grid-gap: 3.16049em 5vw; } }

.dcf-input-group-form {
  align-items: center;
  display: flex;
  flex-wrap: wrap; }

.dcf-input-group {
  display: flex;
  flex: 1 1 50%; }

.dcf-input-group .dcf-input-text {
  flex: 1 1 auto; }

.dcf-input-group,
.dcf-input-group .dcf-input-text {
  min-width: 0; }

.dcf-input-group .dcf-btn {
  align-items: center;
  display: flex; }

.dcf-input-group-addon {
  align-items: center;
  display: flex;
  text-align: center;
  white-space: nowrap; }

.dcf-mark {
  background-color: #ff0;
  padding: 0.17798em 0.42188em; }

.dcf-modal-overlay {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity .5s ease-out, visibility 0s linear .6s;
  visibility: hidden;
  width: 100%;
  z-index: 999; }
  .dcf-modal-overlay.dcf-is-open {
    opacity: 1;
    pointer-events: auto;
    transition: opacity .2s ease-out;
    visibility: visible; }

.dcf-modal-content {
  height: auto;
  max-height: 100%;
  width: 90%; }

.dcf-close-modal {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: absolute;
  right: 1%;
  top: 1%; }

@media only screen and (min-width: 55.925em) {
  .dcf-nav-toggle {
    display: none; } }

.dcf-lead {
  font-size: 1.125em; }

.dcf-dropcap::first-letter {
  float: left; }

.dcf-search-results-wrapper {
  height: calc(100% - 9.98872em); }

.dcf-search-results {
  padding-right: 1.77778em; }

@supports (display: grid) {
  .dcf-search-results-directory {
    display: grid;
    grid-gap: 3.16049em 5vw;
    grid-template-columns: repeat(auto-fit, minmax(13.31829em, 1fr)); } }

@media only screen and (max-width: 55.925em) {
  .dcf-search-toggle {
    left: 50%; } }

@media only screen and (min-width: 55.925em) {
  @supports (display: grid) {
    .dcf-search {
      grid-area: search; }
    .dcf-search-results {
      display: grid;
      grid-gap: 3.16049em 5vw;
      grid-template-areas: "web directory";
      grid-template-columns: 1fr 1fr; }
    .dcf-search-results-web {
      grid-area: web; }
    .dcf-search-results-directory {
      grid-area: directory; } } }

.dcf-table thead,
.dcf-table tbody {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #73E3CC;
  font-size: 0.84375em; }

.dcf-table thead th,
.dcf-table thead td {
  padding-bottom: 0.5625rem;
  vertical-align: bottom; }

.dcf-table tbody th,
.dcf-table tbody td {
  padding-bottom: 0.5625rem;
  padding-top: 0.5625rem;
  vertical-align: top; }

.dcf-table th:not(:last-child),
.dcf-table td:not(:last-child) {
  padding-right: 1.33333rem; }

.nc-btnlink {
  appearance: none;
  font-family: "Assistant", Verdana, serif;
  font-size: 0.75em;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-decoration: none;
  position: relative;
  margin-top: 2.37037rem;
  margin-left: 2.37037rem; }
  .nc-btnlink-back {
    float: left;
    padding-right: 1.5rem; }
    .nc-btnlink-back:before {
      content: "";
      position: absolute;
      width: 2.37037rem;
      height: 2.37037rem;
      background-color: #73E3CC;
      z-index: -1;
      left: -1.5rem;
      top: 50%;
      transform: translateY(-50%); }
  .nc-btnlink-text {
    margin-right: 0.42188rem;
    position: relative;
    overflow: hidden; }
    .nc-btnlink-text:before {
      content: '';
      position: absolute;
      transition: transform .5s ease;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: #144b60;
      transform: translateX(-100%); }
  .nc-btnlink:visited {
    color: #205468; }
  .nc-btnlink:hover .nc-btnlink-text, .nc-btnlink:active .nc-btnlink-text {
    transform: skewX(-15deg); }
    .nc-btnlink:hover .nc-btnlink-text:before, .nc-btnlink:active .nc-btnlink-text:before {
      transform: translateX(0); }
  .nc-btnlink .icon-next {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    fill: #73E3CC; }

.nc-theme .dcf-btn {
  appearance: none;
  font-family: "Assistant", Verdana, serif;
  font-size: 0.75em;
  font-weight: bold;
  letter-spacing: 0.1em;
  padding-left: 1.77778em;
  padding-right: 1.77778em;
  padding-top: 1em;
  padding-bottom: 1em;
  position: relative;
  transform: skewX(-15deg);
  margin-right: 1rem;
  transition: all 1s ease-in-out; }
  .nc-theme .dcf-btn:before {
    border-width: 2px;
    border-style: solid;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 3px; }
  .nc-theme .dcf-btn .icon-arrow-wrapper {
    position: relative;
    padding-left: 0.17798rem;
    padding-right: 1rem;
    transition: 0.5s;
    display: inline; }
  .nc-theme .dcf-btn:hover .icon-arrow-wrapper {
    margin-right: 0.42188rem; }
  .nc-theme .dcf-btn .icon-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.5s;
    right: 0; }

.nc-theme .dcf-btn-primary, .nc-theme .dcf-btn-primary:link {
  background-color: #144b60;
  color: #fff;
  transition: border 400ms ease-out, background-color 400ms ease-out;
  box-shadow: 6px 6px 0 #3B3C3C; }
  .nc-theme .dcf-btn-primary:before, .nc-theme .dcf-btn-primary:link:before {
    border-color: #73E3CC; }

.nc-theme .dcf-btn-primary:focus {
  transform: skewX(-15deg) translateY(15%); }
  .nc-theme .dcf-btn-primary:focus .arrow-one {
    animation: primary-arrow-anim 1s infinite 0.3s; }
  .nc-theme .dcf-btn-primary:focus .arrow-two {
    transform: translateX(5px);
    animation: primary-arrow-anim 1s infinite 0.5s linear; }
  .nc-theme .dcf-btn-primary:focus .arrow-three {
    transform: translateX(10px);
    animation: primary-arrow-anim 1s infinite 0.9s; }

.nc-theme .dcf-btn-primary:visited {
  background-color: #546a72;
  border-color: transparent;
  color: #fff;
  box-shadow: 6px 6px 0 #85959a; }
  .nc-theme .dcf-btn-primary:visited:before {
    border-color: #85959a; }

.nc-theme .dcf-btn-primary:hover {
  background-color: #0b2a36;
  border-color: #0b2a36;
  color: #fff;
  transition: border 200ms ease-out, background-color 200ms ease-out;
  box-shadow: 10px 10px 0 #4d9f0c; }
  .nc-theme .dcf-btn-primary:hover .arrow-one {
    animation: primary-arrow-anim 1s infinite 0.3s; }
  .nc-theme .dcf-btn-primary:hover .arrow-two {
    transform: translateX(5px);
    animation: primary-arrow-anim 1s infinite 0.5s linear; }
  .nc-theme .dcf-btn-primary:hover .arrow-three {
    transform: translateX(10px);
    animation: primary-arrow-anim 1s infinite 0.9s; }

.nc-theme .dcf-btn-primary:active, .nc-theme .dcf-btn-primary.dcf-active {
  background-color: #0b2a36;
  border-color: #0b2a36;
  color: #fff; }

.nc-theme .dcf-btn-secondary, .nc-theme .dcf-btn-secondary:link {
  background-color: #73E3CC;
  color: #144b60;
  transition: background-color 400ms ease-out, border-color 400ms ease-out, color 400ms ease-out;
  box-shadow: 6px 6px 0 #3B3C3C; }

.nc-theme .dcf-btn-secondary .arrow-one, .nc-theme .dcf-btn-secondary .arrow-two, .nc-theme .dcf-btn-secondary .arrow-three {
  fill: #fff; }

.nc-theme .dcf-btn-secondary:focus {
  transform: skewX(-15deg) translateY(15%); }
  .nc-theme .dcf-btn-secondary:focus .arrow-one {
    animation: secondary-arrow-anim 1s infinite 0.3s; }
  .nc-theme .dcf-btn-secondary:focus .arrow-two {
    transform: translateX(5px);
    animation: secondary-arrow-anim 1s infinite 0.5s linear; }
  .nc-theme .dcf-btn-secondary:focus .arrow-three {
    transform: translateX(10px);
    animation: secondary-arrow-anim 1s infinite 0.9s; }

.nc-theme .dcf-btn-secondary:visited {
  background-color: #546a72;
  border-color: transparent;
  color: #fff;
  box-shadow: 6px 6px 0 #85959a; }
  .nc-theme .dcf-btn-secondary:visited:before {
    border-color: #85959a; }

.nc-theme .dcf-btn-secondary:hover {
  background-color: rgba(11, 42, 54, 0.1);
  border-color: currentColor;
  color: #0b2a36;
  transition: background-color 200ms ease-out, border-color 200ms ease-out, color 200ms ease-out;
  box-shadow: 10px 10px 0 #4d9f0c; }
  .nc-theme .dcf-btn-secondary:hover .arrow-one {
    animation: secondary-arrow-anim 1s infinite 0.3s; }
  .nc-theme .dcf-btn-secondary:hover .arrow-two {
    transform: translateX(5px);
    animation: secondary-arrow-anim 1s infinite 0.5s linear; }
  .nc-theme .dcf-btn-secondary:hover .arrow-three {
    transform: translateX(10px);
    animation: secondary-arrow-anim 1s infinite 0.9s; }

.nc-theme .dcf-btn-secondary:active, .nc-theme .dcf-btn-secondary.dcf-active {
  border-color: currentColor;
  background-color: rgba(11, 42, 54, 0.1); }

.nc-theme .dcf-btn-tertiary,
.nc-theme .dcf-btn-tertiary:link {
  background-color: transparent;
  border: 1px solid transparent;
  color: #144b60;
  text-decoration: underline;
  text-decoration-skip: ink;
  transition: color 400ms ease-out; }

.nc-theme .dcf-btn-tertiary:focus {
  box-shadow: inset 0 0 0 3px #fff, inset 0 0 0 4px currentColor; }

.nc-theme .dcf-btn-tertiary:visited {
  color: #0b2a36; }

.nc-theme .dcf-btn-tertiary:hover {
  color: #0b2a36;
  transition: color 200ms ease-out; }

.nc-theme .dcf-btn-tertiary:active,
.nc-theme .dcf-btn-tertiary.dcf-active {
  color: #0b2a36; }

.nc-theme .dcf-btn-primary-inverse {
  background-color: #fbfbfb;
  border-color: #fbfbfb;
  transition: background-color 400ms ease-out, border 400ms ease-out; }

.nc-theme .dcf-btn-primary-inverse:hover {
  background-color: #fff;
  border-color: #fff;
  transition: background-color 200ms ease-out, border 200ms ease-out; }

.nc-theme .dcf-btn-secondary-inverse {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: currentColor;
  color: #fff;
  transition: background-color 400ms ease-out, border 400ms ease-out; }

.nc-theme .dcf-btn-secondary-inverse:hover {
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 200ms ease-out, border 200ms ease-out; }

/* SVG animations */
@keyframes primary-arrow-anim {
  0% {
    fill: #fff; }
  50% {
    fill: #4d9f0c; }
  100% {
    fill: #fff; } }

@keyframes secondary-arrow-anim {
  0% {
    fill: #144b60; }
  50% {
    fill: #4d9f0c; }
  100% {
    fill: #144b60; } }

.nc-card {
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
  height: 100%;
  padding-bottom: 0;
  border: 6px solid #144b60; }
  .nc-card a {
    text-decoration: none; }
  .nc-card:hover {
    box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
    border-image: linear-gradient(#4f7888, #4d9f0c) 30; }
    .nc-card:hover img {
      transform: rotate(5deg) scale(1.1); }
  .nc-card-preview-image {
    border-bottom: 1px solid #144b60;
    overflow: hidden; }
    .nc-card-preview-image img {
      transition: transform 0.3s ease-in-out; }
  .nc-card-details dd {
    padding-left: 1.125em; }

.nc-theme .dcf-multicolumn-2 {
  column-count: 2;
  column-width: 150px; }

.nc-theme .dcf-multicolumn-gap {
  column-gap: 2.37037em; }

.nc-curvy {
  position: absolute;
  width: 100vw;
  height: 20vw;
  top: 0;
  left: 0;
  margin-left: 0 !important;
  fill: #67e2c9;
  z-index: -1; }

.nc-main-footer {
  border-top: 1px solid rgba(21, 83, 99, 0.2); }

.nc-theme .dcf-logo-link {
  display: flex;
  flex-direction: column;
  position: relative; }
  .nc-theme .dcf-logo-link:after {
    content: "";
    position: absolute;
    height: 26em;
    width: 2em;
    top: 102%;
    background-color: #73E3CC;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='32' viewBox='0 0 16 32'%3E%3Cg fill='%23164b60' fill-opacity='0.47'%3E%3Cpath fill-rule='evenodd' d='M0 24h4v2H0v-2zm0 4h6v2H0v-2zm0-8h2v2H0v-2zM0 0h4v2H0V0zm0 4h2v2H0V4zm16 20h-6v2h6v-2zm0 4H8v2h8v-2zm0-8h-4v2h4v-2zm0-20h-6v2h6V0zm0 4h-4v2h4V4zm-2 12h2v2h-2v-2zm0-8h2v2h-2V8zM2 8h10v2H2V8zm0 8h10v2H2v-2zm-2-4h14v2H0v-2zm4-8h6v2H4V4zm0 16h6v2H4v-2zM6 0h2v2H6V0zm0 24h2v2H6v-2z'/%3E%3C/g%3E%3C/svg%3E");
    transform: skewY(-15deg); }
    @media only screen and (max-width: 55.925em) {
      .nc-theme .dcf-logo-link:after {
        width: 4%;
        left: -0.7em; } }

.nc-theme .logo {
  height: auto;
  width: 2em;
  padding-top: 1em;
  padding-bottom: 1em; }
  @media only screen and (max-width: 55.925em) {
    .nc-theme .logo {
      padding-top: 2em;
      width: 4%;
      position: relative;
      left: -0.7em; } }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor; }

/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
  font-size: 32px;
  color: red;
}
========================================== */
.icon-arrow {
  width: 1rem; }

.icon-next {
  width: 1.5rem; }

.nc-portfolio {
  overflow: hidden;
  padding-bottom: 2.37037rem; }
  @media only screen and (min-width: 41.95625em) {
    .nc-portfolio {
      padding-bottom: 5.61866rem; } }
  .nc-portfolio > *:not(.nc-portfolio-screenshots-wrapper) {
    margin-left: 8.42798rem; }
    @media only screen and (max-width: 55.925em) {
      .nc-portfolio > *:not(.nc-portfolio-screenshots-wrapper) {
        margin-left: 2.0rem; } }
  .nc-portfolio-desc p:first-child::first-letter {
    font-size: 4.74074em;
    line-height: 0.53;
    margin-top: 1.7rem;
    height: 0.4em;
    padding-right: 0.5625rem;
    font-family: "Karma", Georgia, serif;
    float: left;
    color: #144b60; }
  .nc-portfolio-role-tech {
    border: 1px solid rgba(21, 83, 99, 0.28);
    background-color: #fbfbfb;
    padding-left: 1.77778em;
    padding-right: 1.77778em;
    padding-top: 1em;
    padding-bottom: 1em; }
  .nc-portfolio-screenshots-wrapper {
    position: relative; }
    .nc-portfolio-screenshots-wrapper:before {
      content: "";
      position: absolute;
      right: 0;
      background-color: #f1f1f1;
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23165d66' fill-opacity='0.13' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
      width: 5em;
      height: 250em;
      bottom: 15%;
      z-index: -2; }
      @media only screen and (max-width: 41.95625em) {
        .nc-portfolio-screenshots-wrapper:before {
          width: 2em; } }
    .nc-portfolio-screenshots-wrapper:after {
      content: "";
      position: absolute;
      left: 0;
      background-color: #f1f1f1;
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23165d66' fill-opacity='0.13' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
      width: 5em;
      height: 250em;
      bottom: -240em;
      z-index: -2; }
      @media only screen and (max-width: 41.95625em) {
        .nc-portfolio-screenshots-wrapper:after {
          width: 2em; } }
  .nc-portfolio-screenshots {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .nc-portfolio-screenshots figure {
      padding: 1rem;
      flex: 1; }
    .nc-portfolio-screenshots img {
      display: block;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25); }
    .nc-portfolio-screenshots figcaption {
      background: #144b60;
      background: linear-gradient(90deg, #144b60 0%, #4d9f0c 90%);
      padding: 1rem 1.125rem;
      color: #fff; }
  .nc-portfolio-teaser-index {
    position: relative; }
    .nc-portfolio-teaser-index::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 20vw;
      z-index: -1;
      background: linear-gradient(to bottom, #67e2c9 0%, rgba(255, 255, 255, 0) 90%); }
      @media only screen and (min-width: 41.95625em) {
        .nc-portfolio-teaser-index::before {
          background: linear-gradient(to bottom, #67e2c9 0%, rgba(255, 255, 255, 0) 78%); } }
  .nc-portfolio-teaser-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: -1em 0 1em -0.5em;
    justify-content: space-around; }
    @supports (display: grid) {
      .nc-portfolio-teaser-wrapper {
        display: grid;
        grid-column-gap: 1.77778em;
        grid-row-gap: 1.77778em;
        grid-template-columns: repeat(3, 1fr); }
        @media only screen and (max-width: 74.55em) {
          .nc-portfolio-teaser-wrapper {
            grid-template-columns: repeat(2, 1fr); } }
        @media only screen and (max-width: 41.95625em) {
          .nc-portfolio-teaser-wrapper {
            grid-template-columns: 1fr; } } }
  .nc-portfolio-teaser {
    display: inline-block; }
    @media only screen and (min-width: 55.925em) {
      .nc-portfolio-teaser {
        max-width: 49%; } }
    @media only screen and (min-width: 74.55em) {
      .nc-portfolio-teaser {
        max-width: 32%; } }
    @supports (display: grid) {
      .nc-portfolio-teaser {
        max-width: 100%; } }
    .nc-portfolio-teaser a {
      text-decoration: none; }
  .nc-portfolio-cover-img {
    max-height: 15em; }
  .nc-portfolio-tech-inline {
    margin: 0;
    padding: 0; }
    .nc-portfolio-tech-inline > li {
      display: inline;
      list-style-type: none; }
      .nc-portfolio-tech-inline > li:after {
        content: ", "; }
      .nc-portfolio-tech-inline > li:last-child:after {
        content: ""; }
  .nc-portfolio-challenge {
    padding-left: 1.77778em;
    padding-right: 1.77778em;
    padding-top: 1em;
    padding-bottom: 1em; }
  .nc-portfolio-curvy {
    position: absolute;
    z-index: -1;
    fill: #73E3CC;
    fill-opacity: 0.89; }
    .nc-portfolio-curvy--single {
      height: 131%; }

.typing > * {
  overflow: hidden;
  white-space: nowrap;
  animation: typingAnim 5s steps(50);
  position: relative; }
  .typing > *:after {
    content: ". .";
    display: block;
    position: absolute;
    top: 1em;
    left: 0.35em; }
  .typing > *:before {
    content: "";
    position: absolute;
    display: block;
    top: 2.1em;
    left: 0.25em;
    width: 1em;
    height: 0.1em;
    border-radius: 100%;
    background: #fff;
    animation: typingSpeak 0.5s steps(2);
    animation-iteration-count: 5; }

@keyframes typingAnim {
  from {
    width: 0; }
  to {
    width: 100%; } }

@keyframes typingSpeak {
  0% {
    width: 1em;
    height: 0.1em; }
  100% {
    width: 1em;
    height: 0.5em; } }

.nc-theme .dcf-badge {
  background: #f1f1f1;
  background-image: linear-gradient(to right, #fff 0%, #e2ebf0 100%);
  color: #144b60; }

.fonts-loaded body {
  font-family: "Assistant", Verdana, serif; }

.nc-theme main {
  position: relative; }

.fonts-loaded h1 {
  font-family: "Karma", Georgia, serif; }

.dcf-dialog .backdrop {
  background-color: rgba(0, 0, 0, 0.96); }
  @supports (backdrop-filter: blur(6px)) {
    .dcf-dialog .backdrop {
      background-color: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(6px) grayscale(10%); } }

.dcf-fade-up {
  animation: fade-up 2s forwards ease-out; }

#typewriter {
  min-height: 2.8em; }

.dcf-ratio {
  position: relative; }
  .dcf-ratio::before {
    content: '';
    display: block;
    width: 100%; }

.dcf-ratio-16x9::before {
  padding-top: 56.25%; }

.dcf-ratio-4x3::before {
  padding-top: 75%; }

.dcf-ratio-1x1::before {
  padding-top: 100%; }

.dcf-ratio-child {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.dcf-bg-transparent {
  background-color: transparent !important; }

.dcf-bg-white {
  background-color: #fff !important; }

.dcf-bg-overlay-dark {
  background-color: rgba(0, 0, 0, 0.96) !important; }
  @supports (backdrop-filter: blur(6px)) {
    .dcf-bg-overlay-dark {
      background-color: rgba(0, 0, 0, 0.8) !important;
      backdrop-filter: blur(6px) grayscale(10%) !important; } }

.dcf-bg-overlay-light,
.dcf-bg-overlay-light::backdrop {
  background-color: rgba(255, 255, 255, 0.98) !important; }
  @supports (backdrop-filter: blur(6px)) {
    .dcf-bg-overlay-light,
    .dcf-bg-overlay-light::backdrop {
      background-color: rgba(255, 255, 255, 0.95) !important;
      backdrop-filter: blur(6px) grayscale(33%) !important; } }

.dcf-bg-center {
  background-position: center !important; }

.dcf-bg-top {
  background-position: top !important; }

.dcf-bg-right {
  background-position: right !important; }

.dcf-bg-right-top {
  background-position: right top !important; }

.dcf-bg-right-bottom {
  background-position: right bottom !important; }

.dcf-bg-bottom {
  background-position: bottom !important; }

.dcf-bg-left {
  background-position: left !important; }

.dcf-bg-left-top {
  background-position: left top !important; }

.dcf-bg-left-bottom {
  background-position: left bottom !important; }

.dcf-bg-no-repeat {
  background-repeat: no-repeat !important; }

.dcf-bg-repeat {
  background-repeat: repeat !important; }

.dcf-bg-repeat-x {
  background-repeat: repeat-x !important; }

.dcf-bg-repeat-y {
  background-repeat: repeat-y !important; }

.dcf-bg-contain {
  background-size: contain !important; }

.dcf-bg-cover {
  background-size: cover !important; }

.dcf-bg-none {
  background: none !important; }

.dcf-b-current {
  border-color: currentColor !important; }

.dcf-bt-current {
  border-top-color: currentColor !important; }

.dcf-br-current {
  border-right-color: currentColor !important; }

.dcf-bb-current {
  border-bottom-color: currentColor !important; }

.dcf-bl-current {
  border-left-color: currentColor !important; }

.dcf-circle {
  border-radius: 50% !important; }

.dcf-rounded {
  border-radius: 2px !important; }

.dcf-rounded-top {
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important; }

.dcf-rounded-right {
  border-bottom-right-radius: 2px !important;
  border-top-right-radius: 2px !important; }

.dcf-rounded-bottom {
  border-bottom-left-radius: 2px !important;
  border-bottom-right-radius: 2px !important; }

.dcf-rounded-left {
  border-bottom-left-radius: 2px !important;
  border-top-left-radius: 2px !important; }

.dcf-b-solid {
  border-style: solid !important; }

.dcf-bt-solid {
  border-top-style: solid !important; }

.dcf-br-solid {
  border-right-style: solid !important; }

.dcf-bb-solid {
  border-bottom-style: solid !important; }

.dcf-bl-solid {
  border-left-style: solid !important; }

.dcf-b-0 {
  border: 0 !important; }

.dcf-b-1 {
  border-width: 1px !important; }

.dcf-b-2 {
  border-width: 2px !important; }

.dcf-b-3 {
  border-width: 3px !important; }

.dcf-bt-1 {
  border-top-width: 1px !important; }

.dcf-bt-2 {
  border-top-width: 2px !important; }

.dcf-bt-3 {
  border-top-width: 3px !important; }

.dcf-br-1 {
  border-right-width: 1px !important; }

.dcf-br-2 {
  border-right-width: 2px !important; }

.dcf-br-3 {
  border-right-width: 3px !important; }

.dcf-bb-1 {
  border-bottom-width: 1px !important; }

.dcf-bb-2 {
  border-bottom-width: 2px !important; }

.dcf-bb-3 {
  border-bottom-width: 3px !important; }

.dcf-bl-1 {
  border-left-width: 1px !important; }

.dcf-bl-2 {
  border-left-width: 2px !important; }

.dcf-bl-3 {
  border-left-width: 3px !important; }

.dcf-ai-flex-start {
  align-items: flex-start !important; }

.dcf-ai-flex-end {
  align-items: flex-end !important; }

.dcf-ai-center {
  align-items: center !important; }

.dcf-ai-baseline {
  align-items: baseline !important; }

.dcf-ai-stretch {
  align-items: stretch !important; }

.dcf-ai-start {
  align-items: start !important; }

.dcf-ai-end {
  align-items: end !important; }

.dcf-ac-flex-start {
  align-content: flex-start !important; }

.dcf-ac-flex-end {
  align-content: flex-end !important; }

.dcf-ac-center {
  align-content: center !important; }

.dcf-ac-stretch {
  align-content: stretch !important; }

.dcf-ac-around {
  align-content: space-around !important; }

.dcf-ac-between {
  align-content: space-between !important; }

.dcf-ac-evenly {
  align-content: space-evenly !important; }

.dcf-ac-start {
  align-content: start !important; }

.dcf-ac-end {
  align-content: end !important; }

.dcf-as-auto {
  align-self: auto !important; }

.dcf-as-flex-start {
  align-self: flex-start !important; }

.dcf-as-flex-end {
  align-self: flex-end !important; }

.dcf-as-center {
  align-self: center !important; }

.dcf-as-baseline {
  align-self: baseline !important; }

.dcf-as-stretch {
  align-self: stretch !important; }

.dcf-as-start {
  align-self: start !important; }

.dcf-as-end {
  align-self: end !important; }

.dcf-ji-start {
  justify-items: start !important; }

.dcf-ji-end {
  justify-items: end !important; }

.dcf-ji-center {
  justify-items: center !important; }

.dcf-ji-stretch {
  justify-items: stretch !important; }

.dcf-jc-flex-start {
  justify-content: flex-start !important; }

.dcf-jc-flex-end {
  justify-content: flex-end !important; }

.dcf-jc-center {
  justify-content: center !important; }

.dcf-jc-around {
  justify-content: space-around !important; }

.dcf-jc-between {
  justify-content: space-between !important; }

.dcf-jc-evenly {
  justify-content: space-evenly !important; }

.dcf-jc-start {
  justify-content: start !important; }

.dcf-jc-end {
  justify-content: end !important; }

.dcf-jc-stretch {
  justify-content: stretch !important; }

.dcf-js-start {
  justify-self: start !important; }

.dcf-js-center {
  justify-self: center !important; }

.dcf-js-end {
  justify-self: end !important; }

.dcf-js-stretch {
  justify-self: stretch !important; }

.dcf-inverse {
  color: #fbfbfb !important; }

a.dcf-inverse:link {
  color: #fbfbfb !important; }

a.dcf-inverse:visited {
  color: #fbfbfb !important; }

a.dcf-inverse:hover {
  color: #fbfbfb !important; }

a.dcf-inverse:active {
  color: #fbfbfb !important; }

.dcf-d-none {
  display: none !important; }

.dcf-d-block {
  display: block !important; }

.dcf-d-inline {
  display: inline !important; }

.dcf-d-inline-block {
  display: inline-block !important; }

.dcf-d-table {
  display: table !important; }

.dcf-d-table-cell {
  display: table-cell !important; }

.dcf-d-flex {
  display: flex !important; }

.dcf-d-inline-flex {
  display: inline-flex !important; }

@supports (display: grid) {
  .dcf-d-grid {
    display: grid !important; } }

@supports (display: inline-grid) {
  .dcf-d-inline-grid {
    display: inline-grid !important; } }

.dcf-flex-col {
  flex-direction: column !important; }

.dcf-flex-col-rev {
  flex-direction: column-reverse !important; }

.dcf-flex-row {
  flex-direction: row !important; }

.dcf-flex-row-rev {
  flex-direction: row-reverse !important; }

.dcf-flex-wrap {
  flex-wrap: wrap !important; }

.dcf-flex-wrap-rev {
  flex-wrap: wrap-reverse !important; }

.dcf-flex-nowrap {
  flex-wrap: nowrap !important; }

.dcf-flex-none {
  flex: none !important; }

.dcf-flex-initial {
  flex: initial !important; }

.dcf-flex-auto {
  flex: auto !important; }

.dcf-flex-1 {
  flex: 1 !important; }

.dcf-flex-grow-1 {
  flex-grow: 1 !important; }

.dcf-flex-shrink-0 {
  flex-shrink: 0 !important; }

.dcf-float-right {
  float: right !important; }

.dcf-float-left {
  float: left !important; }

.dcf-clearfix::after {
  clear: both !important;
  content: "" !important;
  display: table !important; }

.dcf-col-gap-sm {
  grid-column-gap: 1em !important; }

.dcf-col-gap-md {
  grid-column-gap: 1.77778em !important; }

.dcf-col-gap-lg {
  grid-column-gap: 3.16049em !important; }

.dcf-col-gap-vw {
  grid-column-gap: 3.16049vw !important; }

.dcf-row-gap-sm {
  grid-row-gap: 1em !important; }

.dcf-row-gap-md {
  grid-row-gap: 1.77778em !important; }

.dcf-row-gap-lg {
  grid-row-gap: 3.16049em !important; }

.dcf-h-0 {
  height: 0 !important; }

.dcf-h-1 {
  height: 0.42188em !important; }

.dcf-h-2 {
  height: 0.5625em !important; }

.dcf-h-3 {
  height: 0.75em !important; }

.dcf-h-4 {
  height: 1em !important; }

.dcf-h-5 {
  height: 1.33333em !important; }

.dcf-h-6 {
  height: 1.77778em !important; }

.dcf-h-7 {
  height: 2.37037em !important; }

.dcf-h-8 {
  height: 3.16049em !important; }

.dcf-h-9 {
  height: 4.21399em !important; }

.dcf-h-10 {
  height: 5.61866em !important; }

.dcf-h-100\% {
  height: 100% !important; }

.dcf-h-100vh {
  height: 100vh !important; }

.dcf-h-min-0 {
  min-height: 0 !important; }

.dcf-h-max-100\% {
  max-height: 100% !important; }

.dcf-h-max-100vh {
  max-height: 100vh !important; }

.dcf-w-0 {
  width: 0 !important; }

.dcf-w-1 {
  width: 0.42188em !important; }

.dcf-w-2 {
  width: 0.5625em !important; }

.dcf-w-3 {
  width: 0.75em !important; }

.dcf-w-4 {
  width: 1em !important; }

.dcf-w-5 {
  width: 1.33333em !important; }

.dcf-w-6 {
  width: 1.77778em !important; }

.dcf-w-7 {
  width: 2.37037em !important; }

.dcf-w-8 {
  width: 3.16049em !important; }

.dcf-w-9 {
  width: 4.21399em !important; }

.dcf-w-10 {
  width: 5.61866em !important; }

.dcf-w-100\% {
  width: 100% !important; }

.dcf-w-100vw {
  width: 100vw !important; }

.dcf-w-min-0 {
  min-width: 0 !important; }

.dcf-w-max-100\% {
  max-width: 100% !important; }

.dcf-w-max-100vw {
  max-width: 100vw !important; }

.dcf-m-auto {
  margin: auto !important; }

.dcf-m-0 {
  margin: 0 !important; }

.dcf-m-1 {
  margin: 0.42188em !important; }

.dcf-m-2 {
  margin: 0.5625em !important; }

.dcf-m-3 {
  margin: 0.75em !important; }

.dcf-m-4 {
  margin: 1em !important; }

.dcf-m-5 {
  margin: 1.33333em !important; }

.dcf-m-6 {
  margin: 1.77778em !important; }

.dcf-m-7 {
  margin: 2.37037em !important; }

.dcf-m-8 {
  margin: 3.16049em !important; }

.dcf-m-9 {
  margin: 4.21399em !important; }

.dcf-m-10 {
  margin: 5.61866em !important; }

.dcf-mt-auto {
  margin-top: auto !important; }

.dcf-mt-0 {
  margin-top: 0 !important; }

.dcf-mt-1 {
  margin-top: 0.42188em !important; }

.dcf-mt-2 {
  margin-top: 0.5625em !important; }

.dcf-mt-3 {
  margin-top: 0.75em !important; }

.dcf-mt-4 {
  margin-top: 1em !important; }

.dcf-mt-5 {
  margin-top: 1.33333em !important; }

.dcf-mt-6 {
  margin-top: 1.77778em !important; }

.dcf-mt-7 {
  margin-top: 2.37037em !important; }

.dcf-mt-8 {
  margin-top: 3.16049em !important; }

.dcf-mt-9 {
  margin-top: 4.21399em !important; }

.dcf-mt-10 {
  margin-top: 5.61866em !important; }

.dcf-mr-auto {
  margin-right: auto !important; }

.dcf-mr-0 {
  margin-right: 0 !important; }

.dcf-mr-1 {
  margin-right: 0.42188em !important; }

.dcf-mr-2 {
  margin-right: 0.5625em !important; }

.dcf-mr-3 {
  margin-right: 0.75em !important; }

.dcf-mr-4 {
  margin-right: 1em !important; }

.dcf-mr-5 {
  margin-right: 1.33333em !important; }

.dcf-mr-6 {
  margin-right: 1.77778em !important; }

.dcf-mr-7 {
  margin-right: 2.37037em !important; }

.dcf-mr-8 {
  margin-right: 3.16049em !important; }

.dcf-mr-9 {
  margin-right: 4.21399em !important; }

.dcf-mr-10 {
  margin-right: 5.61866em !important; }

.dcf-mb-auto {
  margin-bottom: auto !important; }

.dcf-mb-0 {
  margin-bottom: 0 !important; }

.dcf-mb-1 {
  margin-bottom: 0.42188em !important; }

.dcf-mb-2 {
  margin-bottom: 0.5625em !important; }

.dcf-mb-3 {
  margin-bottom: 0.75em !important; }

.dcf-mb-4 {
  margin-bottom: 1em !important; }

.dcf-mb-5 {
  margin-bottom: 1.33333em !important; }

.dcf-mb-6 {
  margin-bottom: 1.77778em !important; }

.dcf-mb-7 {
  margin-bottom: 2.37037em !important; }

.dcf-mb-8 {
  margin-bottom: 3.16049em !important; }

.dcf-mb-9 {
  margin-bottom: 4.21399em !important; }

.dcf-mb-10 {
  margin-bottom: 5.61866em !important; }

.dcf-ml-auto {
  margin-left: auto !important; }

.dcf-ml-0 {
  margin-left: 0 !important; }

.dcf-ml-1 {
  margin-left: 0.42188em !important; }

.dcf-ml-2 {
  margin-left: 0.5625em !important; }

.dcf-ml-3 {
  margin-left: 0.75em !important; }

.dcf-ml-4 {
  margin-left: 1em !important; }

.dcf-ml-5 {
  margin-left: 1.33333em !important; }

.dcf-ml-6 {
  margin-left: 1.77778em !important; }

.dcf-ml-7 {
  margin-left: 2.37037em !important; }

.dcf-ml-8 {
  margin-left: 3.16049em !important; }

.dcf-ml-9 {
  margin-left: 4.21399em !important; }

.dcf-ml-10 {
  margin-left: 5.61866em !important; }

.dcf-obj-fit-contain {
  height: 100%;
  width: 100%;
  font-family: 'object-fit: contain;'; }
  @supports (object-fit: contain) {
    .dcf-obj-fit-contain {
      object-fit: contain !important; } }

.dcf-obj-fit-cover {
  height: 100%;
  width: 100%;
  font-family: 'object-fit: cover;'; }
  @supports (object-fit: cover) {
    .dcf-obj-fit-cover {
      object-fit: cover !important; } }

.dcf-1st {
  order: 1 !important; }

.dcf-2nd {
  order: 2 !important; }

.dcf-3rd {
  order: 3 !important; }

@media only screen and (min-width: 41.95625em) {
  .dcf-1st\@sm {
    order: 1 !important; }
  .dcf-2nd\@sm {
    order: 2 !important; }
  .dcf-3rd\@sm {
    order: 3 !important; } }

@media only screen and (min-width: 55.925em) {
  .dcf-1st\@md {
    order: 1 !important; }
  .dcf-2nd\@md {
    order: 2 !important; }
  .dcf-3rd\@md {
    order: 3 !important; } }

@media only screen and (min-width: 74.55em) {
  .dcf-1st\@lg {
    order: 1 !important; }
  .dcf-2nd\@lg {
    order: 2 !important; }
  .dcf-3rd\@lg {
    order: 3 !important; } }

@media only screen and (min-width: 99.375em) {
  .dcf-1st\@xl {
    order: 1 !important; }
  .dcf-2nd\@xl {
    order: 2 !important; }
  .dcf-3rd\@xl {
    order: 3 !important; } }

.dcf-overflow-visible {
  overflow: visible !important; }

.dcf-overflow-hidden {
  overflow: hidden !important; }

.dcf-overflow-auto {
  overflow: auto !important; }

.dcf-overflow-x-auto {
  overflow-x: auto !important;
  -webkit-overflow-scrolling: touch; }

.dcf-overflow-y-auto {
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch; }

.dcf-overflow-scroll {
  overflow: scroll !important;
  -webkit-overflow-scrolling: touch; }

.dcf-overflow-x-scroll {
  overflow-x: scroll !important;
  -webkit-overflow-scrolling: touch; }

.dcf-overflow-y-scroll {
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch; }

.dcf-p-0 {
  padding: 0 !important; }

.dcf-p-1 {
  padding: 0.42188em !important; }

.dcf-p-2 {
  padding: 0.5625em !important; }

.dcf-p-3 {
  padding: 0.75em !important; }

.dcf-p-4 {
  padding: 1em !important; }

.dcf-p-5 {
  padding: 1.33333em !important; }

.dcf-p-6 {
  padding: 1.77778em !important; }

.dcf-p-7 {
  padding: 2.37037em !important; }

.dcf-p-8 {
  padding: 3.16049em !important; }

.dcf-p-9 {
  padding: 4.21399em !important; }

.dcf-p-10 {
  padding: 5.61866em !important; }

.dcf-pt-0 {
  padding-top: 0 !important; }

.dcf-pt-1 {
  padding-top: 0.42188em !important; }

.dcf-pt-2 {
  padding-top: 0.5625em !important; }

.dcf-pt-3 {
  padding-top: 0.75em !important; }

.dcf-pt-4 {
  padding-top: 1em !important; }

.dcf-pt-5 {
  padding-top: 1.33333em !important; }

.dcf-pt-6 {
  padding-top: 1.77778em !important; }

.dcf-pt-7 {
  padding-top: 2.37037em !important; }

.dcf-pt-8 {
  padding-top: 3.16049em !important; }

.dcf-pt-9 {
  padding-top: 4.21399em !important; }

.dcf-pt-10 {
  padding-top: 5.61866em !important; }

.dcf-pr-0 {
  padding-right: 0 !important; }

.dcf-pr-1 {
  padding-right: 0.42188em !important; }

.dcf-pr-2 {
  padding-right: 0.5625em !important; }

.dcf-pr-3 {
  padding-right: 0.75em !important; }

.dcf-pr-4 {
  padding-right: 1em !important; }

.dcf-pr-5 {
  padding-right: 1.33333em !important; }

.dcf-pr-6 {
  padding-right: 1.77778em !important; }

.dcf-pr-7 {
  padding-right: 2.37037em !important; }

.dcf-pr-8 {
  padding-right: 3.16049em !important; }

.dcf-pr-9 {
  padding-right: 4.21399em !important; }

.dcf-pr-10 {
  padding-right: 5.61866em !important; }

.dcf-pb-0 {
  padding-bottom: 0 !important; }

.dcf-pb-1 {
  padding-bottom: 0.42188em !important; }

.dcf-pb-2 {
  padding-bottom: 0.5625em !important; }

.dcf-pb-3 {
  padding-bottom: 0.75em !important; }

.dcf-pb-4 {
  padding-bottom: 1em !important; }

.dcf-pb-5 {
  padding-bottom: 1.33333em !important; }

.dcf-pb-6 {
  padding-bottom: 1.77778em !important; }

.dcf-pb-7 {
  padding-bottom: 2.37037em !important; }

.dcf-pb-8 {
  padding-bottom: 3.16049em !important; }

.dcf-pb-9 {
  padding-bottom: 4.21399em !important; }

.dcf-pb-10 {
  padding-bottom: 5.61866em !important; }

.dcf-pl-0 {
  padding-left: 0 !important; }

.dcf-pl-1 {
  padding-left: 0.42188em !important; }

.dcf-pl-2 {
  padding-left: 0.5625em !important; }

.dcf-pl-3 {
  padding-left: 0.75em !important; }

.dcf-pl-4 {
  padding-left: 1em !important; }

.dcf-pl-5 {
  padding-left: 1.33333em !important; }

.dcf-pl-6 {
  padding-left: 1.77778em !important; }

.dcf-pl-7 {
  padding-left: 2.37037em !important; }

.dcf-pl-8 {
  padding-left: 3.16049em !important; }

.dcf-pl-9 {
  padding-left: 4.21399em !important; }

.dcf-pl-10 {
  padding-left: 5.61866em !important; }

.dcf-static {
  position: static !important; }

.dcf-relative {
  position: relative !important; }

.dcf-absolute {
  position: absolute !important; }

.dcf-fixed {
  position: fixed !important; }

@supports (position: sticky) {
  .dcf-sticky {
    position: sticky !important; } }

.dcf-pin-top {
  top: 0 !important; }

.dcf-pin-right {
  right: 0 !important; }

.dcf-pin-bottom {
  bottom: 0 !important; }

.dcf-pin-left {
  left: 0 !important; }

.dcf-fill-current {
  fill: currentColor !important; }

.dcf-stroke-current {
  stroke: currentColor !important; }

.dcf-txt-base {
  font-size: 1rem; }

.dcf-txt-3xs {
  font-size: 0.5625em; }

.dcf-txt-2xs {
  font-size: 0.63281em; }

.dcf-txt-xs {
  font-size: 0.75em; }

.dcf-txt-sm {
  font-size: 0.84375em; }

.dcf-txt-md {
  font-size: 1em; }

.dcf-txt-lg {
  font-size: 1.125em; }

.dcf-txt-h6 {
  font-size: 1.33333em; }

.dcf-txt-h5 {
  font-size: 1.5em; }

.dcf-txt-h4 {
  font-size: 1.77778em; }

.dcf-txt-h3 {
  font-size: 2.0em; }

.dcf-txt-h2 {
  font-size: 2.37037em; }

.dcf-txt-h1 {
  font-size: 2.66667em; }

.dcf-txt-xl {
  font-size: 3.16049em; }

.dcf-txt-2xl {
  font-size: 3.55556em; }

.dcf-txt-3xl {
  font-size: 4.21399em; }

.dcf-txt-4xl {
  font-size: 4.74074em; }

.dcf-txt-5xl {
  font-size: 5.61866em; }

.dcf-txt-6xl {
  font-size: 6.32099em; }

.dcf-txt-center {
  text-align: center !important; }

.dcf-txt-left {
  text-align: left !important; }

.dcf-txt-right {
  text-align: right !important; }

.dcf-txt-baseline {
  vertical-align: baseline !important; }

.dcf-txt-top {
  vertical-align: top !important; }

.dcf-txt-middle {
  vertical-align: middle !important; }

.dcf-txt-bottom {
  vertical-align: bottom !important; }

.dcf-txt-text-top {
  vertical-align: text-top !important; }

.dcf-txt-text-bottom {
  vertical-align: text-bottom !important; }

.dcf-txt-decor-none {
  text-decoration: none !important; }

.dcf-txt-decor-hover {
  text-decoration: none !important; }

.dcf-txt-decor-hover:hover {
  text-decoration: underline !important; }

.dcf-txt-nowrap {
  white-space: nowrap !important; }

.dcf-truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important; }

.dcf-roman {
  font-style: normal !important; }

.dcf-italic {
  font-style: italic !important; }

.dcf-regular {
  font-weight: normal !important; }

.dcf-bold {
  font-weight: bold !important; }

.dcf-lh-1 {
  line-height: 1 !important; }

.dcf-lh-2 {
  line-height: 1.125 !important; }

.dcf-lh-3 {
  line-height: 1.33333 !important; }

.dcf-lh-4 {
  line-height: 1.5 !important; }

.dcf-capitalize {
  text-transform: capitalize !important; }

.dcf-lowercase {
  text-transform: lowercase !important; }

.dcf-uppercase {
  text-transform: uppercase !important; }

.dcf-case-reset {
  text-transform: none !important; }

.dcf-measure {
  max-width: 31.56929rem !important; }

.dcf-invisible {
  visibility: hidden !important; }

.dcf-visible {
  visibility: visible !important; }

.dcf-sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; }
