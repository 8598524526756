//////////////////////////
// !THEME / MIXINS / FONTS
//////////////////////////

/// !Serif
@mixin font-serif($imp:null) {
    font-family: 'Karma', Georgia, serif $imp;
}

// !Sans Serif
@mixin font-sans($imp:null) {
    font-family: 'Assistant', Verdana, serif $imp;
}