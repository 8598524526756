h1 {
    color: $dark-teal;
    
    @supports (-webkit-text-fill-color: transparent) {
        background: linear-gradient(90deg, $dark-teal 0%, #4d9f0c 90%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

h2, 
h3, 
h4, 
h5, 
h6 {
    padding-top: #{ms(4)}em;
}