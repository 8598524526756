//////////////////////////////
// !THEME / VARIABLES / COLORS
//////////////////////////////
// !BRAND COLORS
// Define brand colors
$color-brand-alpha: #144b60;
$color-brand-beta: #fbfbfb;
$color-brand-gamma: #73E3CC;
$color-brand-delta: #2277ab; // TODO: finalize value (must meet WCAG AA)
// Assign names to brand colors
$dark-teal: $color-brand-alpha;
$faint-gray: $color-brand-beta;
$teal: $color-brand-gamma;
$blue: $color-brand-delta;
// !UI COLORS
// Define user interface (neutral) colors
$white: #fff;
$black: #3B3C3C;
$ui-100: mix($faint-gray, $black, 95%); // lightest
$ui-200: mix($faint-gray, $black, 84%);
$ui-300: mix($faint-gray, $black, 75%);
$ui-400: mix($faint-gray, $black, 63%);
$ui-500: mix($faint-gray, $black, 56%);
$ui-600: mix($faint-gray, $black, 43%);
$ui-700: mix($faint-gray, $black, 32%);
$ui-800: mix($faint-gray, $black, 24%);
$ui-900: mix($faint-gray, $black, 18%); // darkest
// Assign colors to elements
// Body background color (usually white, off-white, or very light gray)
$color-body-bg: $faint-gray;
// Must meet WCAG AA (minimum contrast ratio of 4.5)
// Strive for WCAG AAA (minimum contrast ratio of 7.0) for body text
$color-body: $black;
// Must meet WCAG AA (minimum contrast ratio of 4.5)
// Strive for WCAG AAA (minimum contrast ratio of 7.0) for heading text
$color-heading: $black;
// Must meet WCAG AA (minimum contrast ratio of 4.5)
$color-light-text: $ui-700;
// Must meet WCAG AA (minimum contrast ratio of 4.5)
$color-lightest-text: $ui-600;
// !Links
$color-link: $dark-teal;
$color-link-visited: darken($color-link, 10%);
$color-link-hover: darken($color-link, 10%);
$color-link-active: darken($color-link, 10%);
$color-link-underline: scale-color($color-link, $lightness: 50%, $saturation: -50%);
// $color-link-visited: scale-color($color-link, $lightness: -10%, $saturation: -100%);
// $color-link-hover: scale-color($color-link, $lightness: -20%, $saturation: 100%);
// $color-link-active: scale-color($color-link, $lightness: -30%, $saturation: 100%);
// !Buttons
$color-button: $color-link;
$color-button-visited: $color-link-visited;
$color-button-hover: $color-link-hover;
$color-button-active: $color-link-active;
// !Borders
$color-border: $teal;
$color-border-hover: darken($color-border, 10%);
// $border-color: scale-color($dark-teal, $saturation: -100%);
// !Marks (Highlights) Background Color
$color-mark: #ff0;
// !Breadcrumbs
$color-breadcrumb: $teal;
// !Badges
$color-badge: #fdfbfb;
// !Footer
$color-foot: $faint-gray;
$color-footer-bg: $dark-teal;
$color-footer-link: $faint-gray;
$color-footer-link-visited: $faint-gray; // TODO: determine visited state styles
$color-footer-link-hover: $faint-gray; // TODO: determine hover state styles
$color-footer-link-active: $faint-gray; // TODO: determine active state styles
// !Define contextual colors
$color-active: rgba(0, 0, 0, .1);
$color-info: rgba(0, 102, 255, .1);
$color-success: rgba(0, 153, 0, .1);
$color-warning: rgba(255, 102, 0, .1);
$color-danger: rgba(255, 0, 0, .1);
// $theme-color-key: 'base' !default;
//
// $theme-colors: (
//   'primary': (
//     'base': #d00000
// //     'base': hsla(0,100%,41%,1.0) // #d00000
//   ),
//   'accent': (
//     'base': #fefdfa
// //     'base': hsla(45,67%,99%,1.0) // #fefdfa
//   )
// );
//
// $theme-opacity: (
//   'light': 0.75, // opacity used with lighter colors
//   'dark': 0.25  // opacity used with darker colors
// );
//
// @function theme-color(
//   $name: 'primary',
//   $variant: $theme-color-key,
//   $opacity: 1
// ) {
//   $color: null;
//
//   // Get the color spectrum
//   $color-spectrum: map-get($theme-colors, $name);
//
//   // Get the color variant
//   @if $color-spectrum {
//     $color: map-get($color-spectrum, $variant);
//   }
//
//   // Get the alpha setting
//   $alpha: if(type-of($opacity) == 'number', $opacity, map-get($theme-opacity, $opacity));
//
//   // Set the alpha of the color
//   @if $alpha {
//     $color: rgba($color, $alpha);
//   }
//
//   @return $color;
// }