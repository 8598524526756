//////////////////////////////
// THEME / COMPONENTS / BADGES
//////////////////////////////

.nc-theme {

    // !Default Badges
    .dcf-badge {
        background: $ui-100;
        background-image: linear-gradient(to right, $white 0%, #e2ebf0 100%);
        color: $dark-teal;
    }
    
}
