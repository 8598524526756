.nc-theme {
    .dcf-logo-link {
        display: flex;
        flex-direction: column;
        position: relative;

        //&:before {
        //    content: "";
        //    position: absolute;
        //    height: 4em;
        //    width: 0.3em;
        //    background-color: $teal;
        //    top: -$length-em-2;
        //    left: 0.2em;
				//
        //    @include mq(md, max, width) {
        //        height: 1.5em;
        //    }
        //}

        &:after {
            content: "";
            position: absolute;
            height: 26em;
            width: 2em;
            top: 102%;
            background-color: $teal;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='32' viewBox='0 0 16 32'%3E%3Cg fill='%23164b60' fill-opacity='0.47'%3E%3Cpath fill-rule='evenodd' d='M0 24h4v2H0v-2zm0 4h6v2H0v-2zm0-8h2v2H0v-2zM0 0h4v2H0V0zm0 4h2v2H0V4zm16 20h-6v2h6v-2zm0 4H8v2h8v-2zm0-8h-4v2h4v-2zm0-20h-6v2h6V0zm0 4h-4v2h4V4zm-2 12h2v2h-2v-2zm0-8h2v2h-2V8zM2 8h10v2H2V8zm0 8h10v2H2v-2zm-2-4h14v2H0v-2zm4-8h6v2H4V4zm0 16h6v2H4v-2zM6 0h2v2H6V0zm0 24h2v2H6v-2z'/%3E%3C/g%3E%3C/svg%3E");
            transform: skewY(-15deg);

            @include mq(md, max, width) {
                width: 4%;
                left: -0.7em;
            }
        }
    }
    .logo {
        height: auto;
        width: 2em;
        padding-top:1em;
        padding-bottom:1em;

        @include mq(md, max, width) {
            padding-top:2em;
            width: 4%;
            position: relative;
            left: -0.7em;
        }
    }
}