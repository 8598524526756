.nc-portfolio {
    overflow: hidden;
    padding-bottom: #{ms(6)}rem;


		@include mq(sm, min, width) {
			padding-bottom: #{ms(12)}rem;
		}

    >*:not(.nc-portfolio-screenshots-wrapper) {
        margin-left: #{ms(15)}rem;

        @include mq(md, max, width) {
            margin-left: #{ms(5)}rem;
        }
    }
    &-desc {
        p:first-child {
            &::first-letter {
                font-size: #{ms(11)}em; // 5.26em
                line-height: 0.53;
                margin-top: 1.7rem;
                height: 0.4em;
                padding-right: #{ms(-4)}rem; // .56rem
                @include font-serif;
                float: left;
                color: $dark-teal;

                    // font-size: 5.4em;
                    // line-height: 0.5;
                    // margin-top: 1.7rem;
                    // height: 0.4em;
                    // padding-right: .5625rem;
                    // font-family: Karma,
                    // Georgia,
                    // serif;
                    // float: left;
            }
        }
    }
    &-role-tech{
        border: 1px solid rgba(21,83,99,0.28);
        background-color: $color-body-bg;
        @include pl-6;
        @include pr-6;
        @include pt-4;
        @include pb-4;
    }

    &-screenshots-wrapper {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            right: 0;
            background-color: $ui-100;
						background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23165d66' fill-opacity='0.13' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
            width: 5em;
            height: 250em;
            bottom: 15%;
            z-index: -2;

            @include mq(sm, max, width) {
                width: 2em;
            }
        }

        &:after {
            content: "";
            position: absolute;
            left: 0;
            background-color: $ui-100;
						background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23165d66' fill-opacity='0.13' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
            width: 5em;
            height: 250em;
            bottom: -240em;
            z-index: -2;

            @include mq(sm, max, width) {
                width: 2em;
            }
        }
    }

    &-screenshots {
        position: relative;

        display: flex;
        justify-content: space-between;
        align-items: center;

        //&:before {
        //    content: "";
        //    position: absolute;
        //    bottom: 6%;
        //    width: 100%;
        //    height: 73%;
        //    z-index: -1;
        //    background-color: $teal;
        //}

        figure {
            padding: #{ms(0)}rem;
            flex: 1;
        }

        img {
            display: block;
            box-shadow: 0 1px 5px 0 rgba(0,0,0,.25);
        }

        figcaption {
            background: $dark-teal;
            // background: linear-gradient(90deg, rgba(20, 75, 96, 1) 0%, rgba(28, 142, 118, 1) 100%);
            background: linear-gradient(90deg, $dark-teal 0%, #4d9f0c 90%);
            padding: #{ms(0)}rem #{ms(1)}rem;
            color: $white;
        }
    }

    // Front page teaser
    &-teaser-index {
        position: relative;
        &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            left: 0;
            top: 20vw; //after the height of the svg header
            z-index: -1;
            background: linear-gradient(to bottom, rgb(103, 226, 201) 0%, rgba(255, 255, 255, 0) 90%);

						@include mq(sm, min, width) {
							background: linear-gradient(to bottom, rgb(103, 226, 201) 0%, rgba(255, 255, 255, 0) 78%);
						}
        }


    }

    &-teaser-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: -1em 0 1em -0.5em;
        justify-content: space-around; 

        @supports (display:grid) {
            display: grid;
            @include col-gap-md;
            @include row-gap-md;
            grid-template-columns: repeat(3, 1fr);
            @include mq(lg, max, width) {
                grid-template-columns: repeat(2, 1fr);
            }
            @include mq(sm, max, width) {
                grid-template-columns: 1fr;
            }
        }
    }

    &-teaser {
        display: inline-block;
        
        @include mq(md, min, width) {
            max-width: 49%;
        }
        @include mq(lg, min, width) {
            max-width: 32%;
        }

        @supports (display:grid) {
            max-width: 100%;
        }

        a {
            text-decoration: none;
        }
    }

    &-cover-img {
        max-height: 15em;
        // border: 5px solid $teal;
    }

    &-tech-inline {
        // display: inline;
        margin: 0;
        padding: 0;

        > li {
            display: inline;
            list-style-type: none;

            &:after {
                content: ", ";
            }

            &:last-child:after {
                content: "";
            }
        }
    }

	&-challenge {
		@include pl-6;
		@include pr-6;
		@include pt-4;
		@include pb-4;
	}

	&-curvy {
		position: absolute;
		z-index: -1;
		fill: $teal;
		fill-opacity: 0.89;

		&--single {
			height: 131%;
		}
	}
}
