.typing {
    > * {
        overflow: hidden;
        white-space: nowrap;
        animation: typingAnim 5s steps(50);
        position: relative;

        &:after {
            content: ". .";
            display: block;
            position: absolute;
            top: 1em;
            left: 0.35em;
        }

        &:before {
            content: "";
            position: absolute;
            display: block;
            top: 2.1em;
            left: 0.25em;
            width: 1em;
            height: 0.1em;
            border-radius: 100%;
            background: #fff;
            animation: typingSpeak .5s steps(2);
            animation-iteration-count: 5;
        }
    }
}

@keyframes typingAnim{
    from { width: 0; }
    to { width: 100% }
}

@keyframes typingSpeak {
    0% { width: 1em; height: 0.1em }
    100% { width: 1em; height: 0.5em }
}