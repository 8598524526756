.nc-btnlink{
    appearance: none; // remove after added to DCF core
    // border-radius: 0;
    // border-style: solid;
    // border-width: 2px;
    @include font-sans;
    @include txt-xs;
    font-weight: bold;
    @include ls-2; 
    //   @include lh1;
    // padding-bottom: #{ms(-1)}em;
    // @include pl-6;
    // @include pr-6;
    // @include pt-4;
    // @include pb-4; 
    // text-transform: uppercase;
    text-decoration: none;
    position: relative;
    margin-top: #{ms(6)}rem;
    margin-left: #{ms(6)}rem;
    

    &-back {
        float: left;
        padding-right: #{ms(3)}rem;

        &:before {
            content: "";
            position: absolute;
            width: #{ms(6)}rem;
            height: #{ms(6)}rem;
            background-color: $teal;
            z-index: -1;
            left: -#{ms(3)}rem;
            ;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &-text {
        margin-right: #{ms(-6)}rem;
        position: relative;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            transition: transform .5s ease;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background: $dark-teal;
            transform: translateX(-100%);
        }
    }

    &:focus {

    }

    &:visited {
        color: mix($white, $dark-teal, 5%);
        .nc-btnlink-text {
        }
    }
    
    &:hover,
    &:active {
        .nc-btnlink-text {
            
            transform: skewX(-15deg);

            &:before {
                transform: translateX(0);
            }
        }
    }

    &
    .icon-next {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        fill: $teal;
    }
}